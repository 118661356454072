.footer-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
    font-family: "Montserrat";
    color: rgb(83, 83, 83);
}

.footer-text {
    display: inline-block;
    width: 100%;
    padding: 3px 0px;
    font-size: 0.7rem;
    text-align: center
}